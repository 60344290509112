/* 
#006EF2
#3A4651
*/


/* Global */

/* @font-face {
  font-family: HkGrotesk;
  src: url(/src/assets/hkgroteskwide-regular.otf);
} */

@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html{
  overflow-x: hidden;
}

*{
  font-family: 'Hanken Grotesk', sans-serif;
}

body{
  position: relative;
  color: white;
  margin: 0;
}

#AppButtons{
  position: fixed;
  bottom:0;

  z-index: 2;
  /* height: 100vh;
  width: 100vw; */
}

.footer-active{
  transform: translateY(0) !important;
}

#footer-container{
  position: fixed;
  bottom: 0;
  z-index: 2;
  width: 100vw;
  padding: 5vw;
  /* padding-left: 5vw; */
  /* padding-right: 5vw; */
  bottom: 0;
  background-color: #3A4651;
  transform: translateY(150%);
  transition: 1s ease; /* Transition de la largeur */

}

#footer-btn{
  position: fixed;
  bottom: 3%;
  right: 20px;
  z-index: 3;
  
}

.triangle-footer{
  position: absolute;
  transform: translateY(-100%);
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 0 0 100vw;
  border-color: transparent transparent transparent #3A4651;
}

.nav-btn-container{
  position: fixed;
  display: grid;
  gap:10px;
  grid-template-columns: auto;
  grid-template-rows: auto;
  /* height: 100px;*/
  /* width: 100%;  */
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);

  transition: 1s ease; /* Transition de la largeur */
}

.kich-btn-current .kich-btn-touch{
  width: 130px;
  height: 40px;
}

.kich-btn-current  .kich-btn-bg{
  width: 130px;
  height: 40px;
}

.kich-btn{
   /* position: absolute; */
   background: none;
   margin: 0;
   padding: 0;
   border: none;
  
}

.kich-btn-bg{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;

  background-color: #3A4651;
  color: #fffef0;

  border-radius: 5px;
  border: none;

}

.kich-btn-touch{
  position: absolute;
  height: 40px;
  width: 40px;

  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #fffef0;
  color:#3A4651;

  transform: translate(-5px, -5px); 
  
  border-radius: 5px;

  cursor: pointer;

  text-decoration: none;

}

.kich-btn-letter{
  font-weight: bold;
}

.kich-btn-touch:active{
  transform: translate(-2px, -2px); 
}

.kich-btn-bg .bi {
  font-size: 1.5rem;
  color : #3A4651;
}

.kich-btn-active > .kich-btn-bg > .kich-btn-touch{
  transform: translate(-2px, -2px); 
}

.kich-btn-title{
  color: #fff;

  position:absolute;
  padding: 0;
  margin: 0;
}

/* haut */
#nav-btn-kich{
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  /* bottom: 50px;
  left: 70px;
  transform: translate(-50%,0); */
}

#nav-btn-kich > .kich-btn-title{
  top:0;
  left:50%;
  transform: translate(-50%, -150%);
}

/* droite */
#nav-btn-games{
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
  /* bottom: 0;
  left: 120px;
  transform: translate(-50%,0); */
}

#nav-btn-games > .kich-btn-title{
  top:50%;
  right:0;
  transform: translate(125%, 50%);
}

/* gauche */
#nav-btn-network{
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  /* bottom: 0;
  left: 0; */
}

#nav-btn-network > .kich-btn-title{
  top:50%;
  left:0;
  transform: translate(-125%, 50%);
}

/* bas */
#nav-btn-home{
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  /* bottom: 0;
  left: 70px; */
  /* transform: translate(-50%,0); */
}

@media screen and (max-width: 500px) {
  .kich-btn-title, .games-btn-title, .network-btn-title{
    display: none;
  }
}

@media (orientation: portrait){

  .NavChangedActive .nav-btn-container{
    /* transform : translate(0,0); */
    left: 20%;
  
    opacity: 0.5;
  }
  
  .NavChangedActive #footer-btn{
    opacity: 0.5;
    right: 1%
  }
}