/* ------- Network ------- */

#network-container{
    position: relative;
    display: flex;
    width : 100vw;
    height: 100vh;
}

#network-container h1{
  position: absolute;

  top: 20px;
  left: 50%;
  
  transform: translateX(-50%);

  z-index: 1;
}

.network-div{
  position: relative;
  height : 100%;
  width: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  text-decoration: none;

}

.network-div img{
  position: absolute;

  top: 50%;
  left: 50%;

  height: auto;
  width: 75%;

  transform: translate(-50%, -50%);
  transition: transform 0.3s ease, opacity 0.3s ease;

  opacity: 1;
}

.network-div span{
  position: relative;

  width: 100%;
  height: 30px;
  display: flex;
  justify-content:center;
  overflow: hidden;
  z-index: 2;

  margin: 0.5rem
}

.network-div span *{
  bottom: 100%;
  position: absolute;
  text-align: center;
  color: white;

  margin:0;
  
  transition:  0.3s ease;
}

.network-div:hover span *{
  bottom:0;



}

.network-div:hover img{
  transform: translate(-50%, -50%) scale(3);
  opacity: 0.2;
}

@media (orientation: portrait) {

  #network-container h1{
    left: 0; 
    top : 50%;
    transform: translate(-25%, -100%) rotate(-90deg);
  }

  #network-container{
    flex-direction: column;
  }
  .network-div img{
    
  
    width: auto;
    height: 75%;
  
  }
}


  
  