#games-container{
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  display: flex;
}

#games-container nav{
  /* border-right: 1px solid white; */
  
  position: relative;
  display: flex;
  flex-direction: column;
  
  height: 100vh;
  width: 25vw;
}
 
.logo-button{
  position: relative;
  overflow: hidden;

  background-color: transparent;

  border: none;
  cursor: pointer;
  
  flex: 1;
  width: 100%;
  height: 100%;

  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

.logo-button:first-child{
  border-top: none;
}

.logo-button:last-child{
  border-bottom: none;
}

.logo-button:hover span{
  width: 100%;
}

.logo-button img{
  position:absolute;
  height:50%; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
} 

.logo-button span{
  position: absolute;
  overflow: hidden;
  
  /* opacity: 0.5; */
  top: 0;
  left: 0;
  
  height: 100%;
  width: 20%;
  

  transition: 0.3s ease;
}

.logo-button-active span{
  width: 100%
}

#games-infos{ 
  
  height: 100vh;
  width: 75vw;

  overflow: hidden;
}

.game-article{
  position: relative;

  height: 100vh;
  width: auto;

  padding: 4rem 4.5rem 3rem 1rem;
}

.game-article h2{
  position: absolute;
  font-family: Arial;

  top: 0;
  right: 0;
  margin: 0;
  margin-right:1rem ;


  font-size:4rem; 
  color: transparent;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: white;
}

.game-article div{
  display: flex;

  height: 100%;
  width: 100%;
  
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gamertags{
    position: absolute;

    padding: 1rem 0.2rem 1rem 0.2rem;
    display: flex;
    flex-direction: column;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 45px;
    height: auto;
    background-color: #fefefe10;
    border-top: 1px solid #006EF2;
    border-left: 1px solid #006EF2;
    border-bottom: 1px solid #006EF2;
    border-radius: 25px 0 0 25px;

}

.gamertags img{
  padding: 0.5rem;
  margin-top: 0.2rem;
  margin-bottom:0.2rem;
  opacity: 0.6;
  cursor: pointer;
}

.gamertags img:hover{
  opacity: 1
}

@media (orientation: portrait){
  #games-container{
    flex-direction: column;
  }

  #games-container nav {
    height: 15vh;
    width: 100vw;

    flex-direction: row;
  }  

  .logo-button img{
    width: 50%;
    height: auto;
  }

  .logo-button span{
    height: 20%;
    width: 100%;
    box-shadow: none !important;

    bottom: 0;
    top: auto
  }

  .logo-button-active span{
    height: 100%
  }

  #games-infos{  
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100vw;
  }
  
  .game-article{
    padding: 2rem 1rem 3rem 1rem;
  }

  .game-article h2{
    position: relative;
    font-size:3rem; 
  }

  .game-article div{
    height: auto;
  }
 
  /* .gamertags img{
    padding : 0rem 0.7rem 0rem 0.7rem;

    margin-top: 0;
    margin-bottom: 0;
  } */

  .gamertags{
    display: none;
  }

}